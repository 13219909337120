import utils from "@/service/utils";

let menuItems =  [
  {
    title: "İstatistikler",
    icon: "HomeIcon",
    route: "dashboard",
    id:"dashboard"
  },
  {
    title: "[E] İstatistikler",
    icon: "HomeIcon",
    route: "dashboard-screen",
    id: "dashboard-screen",
  },
  {
    title: "Operasyon",
    icon: "CalendarIcon",
    route: "calendar",
    id: "calendar",
  },
  {
    title: "Servisler",
    icon: "ServerIcon",
    id:"services",
    children: [
      {
        title: "Aktif Servisler",
        route: "services",
      },
      {
        title: "Servis Görevleri",
        route: "service-tasks",
      },
      {
        title: "Yeni Servis",
        route: "service-create",
      },
    ],
  },
  {
    title: "Tahsilatlar",
    icon: "DollarSignIcon",
    route: "collections",
    id: "collections",
  },
  {
    title: "Görevler",
    icon: "TrelloIcon",
    id:"tasks",
    children: [
      {
        title: "Görev Listesi",
        route: "task-list",
      },
      {
        title: "Görevlerim",
        route: "task-list-me",
      },
      {
        title: "Yeni Görev",
        route: "task-create",
      },
    ],
  },
  {
    title: "Kargo Takibi",
    icon: "TruckIcon",
    id:"cargotrackinglist",
    children: [
      {
        title: "Listele",
        route: "cargotracking-list",
      },
      {
        title: "Yeni Kargo",
        route: "cargotracking-create",
      },
    ],
  },
  {
    title: "Araç Takibi",
    icon: "CompassIcon",
    id:"carstrackinglist",
    route:"carstracking-list"
  },
  {
    title: "Müşteriler",
    icon: "UsersIcon",
    id:"customers",
    children: [
      {
        title: "Müşteriler",
        route: "customer-list",
      },
      {
        title: "Firmalar",
        route: "company-list",
      },
      {
        title: "Yeni Müşteri",
        route: "customer-create"

      },
    ],
  },
  {
    header: "Yönetim",
  },
  {
    title: "Kullanıcılar",
    icon: "UserIcon",
    id:"users",
    children: [
      {
        title: "Listele",
        route: "user-list",
      },
      {
        title: "Yeni Kullanıcı",
        route: { name: "user-create", },
      },
    ],
  },
  {
    title: "Tanımlamalar",
    icon: "DatabaseIcon",
    id:"management_definitions",
    children: [
      {
        title: "Marka",
        route: "definition-brand",
      },
      {
        title: "Teslimat Görev Tanımlamaları",
        route: "definition-tasktodo",
      },
      {
        title: "Cihaz Sorunları",
        route: "definition-deviceproblem",
      },
      {
        title: "Cihaz Ön Ekspertiz",
        route: "definition-devicepreekspertise",
      },
      {
        title: "Çözüm Önerileri",
        route: "definition-devicesolutionproposal",
      },
      {
        title: "Uygulanabilir İşlemler",
        route: "definition-deviceactiontobetaken",
      },
      {
        title: "Uygulanan İşlemler",
        route: "definition-deviceaction",
      },
      {
        title: "Onarım Talepleri",
        route: "definition-devicerequestofcustomer",
      },
    ],
  },
];


let result = [];
let user = utils.getActiveUser();

result.push( menuItems.find(q=> q.id == 'dashboard') );
result.push( menuItems.find(q=> q.id == 'dashboard-screen') );

//servisler
//operasyonlar
if(  ['list_service','view_service_details','save_device'].some(q=> user.roles.includes(q))  )
{
  result.push( menuItems.find(q=> q.id == 'calendar') );
  result.push( menuItems.find(q=> q.id == 'services') );
}

if( ['list_collection'].some(q=> user.roles.includes(q))  )
{
  result.push( menuItems.find(q=> q.id == 'collections') );
}

result.push( menuItems.find(q=> q.id == 'tasks') );
result.push( menuItems.find(q=> q.id == 'carstrackinglist') );

if( ['list_cargo'].some(q=> user.roles.includes(q))  )
{
  result.push( menuItems.find(q=> q.id == 'cargotrackinglist') );
}

if( ['list_customer','create_customer','update_customer','view_customer'].some(q=> user.roles.includes(q))  )
{
  result.push( menuItems.find(q=> q.id == 'customers') );
}


if( ['list_user','view_user','delete_user','update_user',].some(q=> user.roles.includes(q))  )
{
  result.push( menuItems.find(q=> q.id == 'users') );
}

if( ['create_definition','update_definition','delete_definition','view_definition',].some(q=> user.roles.includes(q))  )
{
  result.push( menuItems.find(q=> q.id == 'management_definitions') );
}

 
export default result;