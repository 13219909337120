<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="servis no, barkod no, müşteri bilgisi ile ara"
        autofocus
        autocomplete="off"
        @keyup="search()"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
      <li v-if="search_result.customers.length > 0">
        <div class="itemtitle">Müşteriler</div>
        <ul >
          <li v-for="(item,index) in search_result.customers" :key="index" class="listitem">
            <router-link :to="{ name: 'customer-update', query: { customer: item.customer_uniq }, }">
            <div>
              {{ item.title }} <span>{{ item.customer_code }}</span>
            </div>
            <div>{{ item.gsm_primary }}</div>
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="search_result.service_forms.length > 0">
        <div class="itemtitle">Servis Form</div>
        <ul >
          <li v-for="(item,index) in search_result.service_forms" :key="index" class="listitem">
             <router-link :to="{ name: 'service', query: { uniq: item.sf_uniq }, }">
              SF No:{{ item.sf_no }}
              <span>Tarih {{ moment(item.sf_date).format('DD.MM.YYYY') }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
 
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { title } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import api from '@/service/api';
import utils from '@/service/utils';


export default {
  components: {
    VuePerfectScrollbar,
  },
  data()
  {
    return {
      search_result:{
        cargo:[],
        customers:[],
        service_forms:[]
      }
    }
  },
  methods: {
    moment(v)
    {
        return utils.moment(v);
    },
    search()
    {
      if( this.searchQuery.length > 3 )
      {
        api.action('search',{ text:this.searchQuery }).then(q=>{
          if(q.success == false)
          {
            utils.notificationToast('warning',q.message);
            return;
          }
          this.search_result = q.data;
        })
      }
    }
  },
  setup() {
    const showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const suggestionSelected = (grpName, suggestion) => {
      // If parameter is not provided => Use current selected
      if (!suggestion) {
        // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        /* eslint-disable no-use-before-define, no-param-reassign */
        if (currentSelected.value !== -1) {
          /* eslint-disable no-use-before-define, no-param-reassign */
          const [grpIndex, itemIndex] = currentSelected.value.split('.')
          grpName = Object.keys(filteredData.value)[grpIndex]
          suggestion = filteredData.value[grpName][itemIndex]
          /* eslint-enable */
        }
      }
      if (grpName === 'pages') router.push(suggestion.route).catch(() => {})
      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({ data: [], searchLimit: 4 })

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    const currentSelected = ref(-1)
    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    const increaseIndex = (val = true) => {
      /* eslint-disable no-lonely-if, no-plusplus */

      // If there's no matching items
      if (!Object.values(filteredData.value).some(grpItems => grpItems.length)) return

      const [grpIndex, itemIndex] = currentSelected.value.split('.')

      const grpArr = Object.entries(filteredData.value)
      const activeGrpTotalItems = grpArr[grpIndex][1].length

      if (val) {
        // If active item is not of last item in grp
        if (activeGrpTotalItems - 1 > itemIndex) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) + 1}`

        // If active item grp is not last in grp list
        } else if (grpIndex < grpArr.length - 1) {
          for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${Number(i)}.0`
              break
            }
          }
        }
      } else {
        // If active item is not of first item in grp
        if (Number(itemIndex)) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) - 1}`

        // If active item grp  is not first in grp list
        } else if (Number(grpIndex)) {
          for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${i}.${grpArr[i][1].length - 1}`
              break
            }
          }
        }
      }
      /* eslint-enable no-lonely-if, no-plusplus */
    }

    return {
      showSearchBar,
      perfectScrollbarSettings,
      title,
      suggestionSelected,
      currentSelected,
      increaseIndex,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.navbar-container .search-input .search-list.show
{
  margin-left:1.5rem !important;
  margin-right:1.5rem !important;
}

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
.listitem
{
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:1rem;
  padding-right: 1rem;
  border-bottom:1px solid #ddd;
  font-size:12px;
  a
  {
    padding: 0px !important;
  }
}
.itemtitle
{
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left:1rem;
  margin-top:0.5rem;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
